@media (max-width: 767px) {
  .show .content-body .container-fluid {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .restaurant-carusel {
      padding: 0 !important;
    }
    .profile-details {
      padding: 0 15px;
      span {
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
.profile-head {
  //background: linear-gradient(136deg, rgba(21, 13, 3, 0.9025) 15%, rgba(22, 15, 6, 0.82194) 30%, rgba(24, 17, 9, 0.739765) 40%, rgba(31, 22, 11, 0.1425) 100%);
  border-radius: 10px;
  .photo-content {
    & > div {
      max-height: 200px;
      overflow: hidden;
      border-radius: 10px;
    }
  }
}
.profile-info {
  border-radius: 10px;
  margin-top: 10px;
}
.restaurant-actions {
  padding: 15px !important;
  font-weight: bold !important;
  font-size: 20px !important;
}
.rating-number {
  font-size: 20px;
  font-weight: 400;
}
.rating-gray {
  color: #8D8B89;
}
.profile-details {
  display: flex;
  vertical-align: center;
  justify-content: space-between;
}