.header-right .header-profile .dropdown-menu {
  top: 0;
  right: -30px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

@media only screen and (max-width: 767px) {
  .header-right .header-profile .dropdown-menu {
    right: -15px;
  }

}