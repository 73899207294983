.bottom-nav {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(241, 241, 241);
  color: #2F2D2A;
  bottom: 0;
  z-index: 5;
  width: 100%;
  padding: 10px;
  height: 50px;
}
[data-theme-version="dark"] .bottom-nav {
  background: #393636;
  color: white;
}