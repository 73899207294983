.order-summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > span {
    font-weight: 900;
  }
}
.dish-total {
  position: absolute;
  top: 10px;
  right: 10px;
}
.order-time {
  .MuiInput-formControl {
    justify-content: center;
    input {
      text-align: center;
      padding: 10px;
      font-size: 46px;
      height: 150px;
      width: 150px;
      border-radius: 75px;
      border: 1px solid black;
    }
  }
}
[data-theme-version="dark"] .order-time {
  .MuiInput-formControl {
    input {
      color: white;
      border: 1px solid white;
    }
  }
}
.MuiPagination-ul {
  justify-content: space-between;
}
[data-theme-version="dark"] {
  .MuiPagination-ul {
    li {
      button {
        color: white;
      }
    }
  }
}
.version-time {
  background: white;
  padding: 2px 5px;
  border-radius: 5px;
  color: #2F2D2A;
  margin-left: 10px;
  font-size: 12px;
}
.emoji {
  font-size: 23px;
  padding: 4px 5px 1px 5px;
  border-radius: 5px;
  background: none;
}
body {
  .make-order {
    text-align: center;
    border-radius: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 3;
    font-size: 18px;
    position: fixed;
    bottom: 55px;
    left: 0;
    margin-left: 18%;
    width: 80%;
  }
  .cancel-order {
    width: 14%;
    z-index: 3;
    text-align: center;
    padding: 20px 10px;
    margin-right: 10px;
    position: fixed;
    bottom: 55px;
    left: 10px;
  }
  .make-order-checkout {
    left: 50%;
    margin-left: -48%;
    width: 96%;
  }
}
@media (max-width: 991px) {
  .user-orders {
    tr {
      td {
        padding: 5px;
        text-align: center;
      }
      td:nth-child(1) {
        padding-left: 0;
      }
      td:nth-last-child {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}
.order {
  background: #ffffff;
  margin-bottom: 10px;
  border-radius: 10px;
  padding: 5px 15px;
  box-shadow: 0 2px 8px rgba(29, 10, 3, 0.08);
  .order-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dish-price {
    color: #F99724;
  }
  created {
    background: linear-gradient(153.09deg, #FFA149 -29.54%, #FB4A35 100%);
  }
  payed {
    background: linear-gradient(153.09deg, #FFA149 -29.54%, #FB4A35 100%);
  }
  cooking {
    background: #2F4CDD;
  }
  ready {
    background: linear-gradient(153.09deg, #C0F168 -29.54%, #79BB25 60%);
  }
  on_delivery {
    background: linear-gradient(158.33deg, #FA392D -8.65%, #C61E14 112.79%);
  }
}

[data-theme-version="dark"] .orders .order {
    background: #262626;
}

// Active orders

[data-theme-version="dark"] .reply-order-message {
  background: #262626;
  input {
    background: #262626;
    color: white;
  }
  textarea {
    background: #262626;
    color: white;
  }
}

.reply-order-message {
  position: fixed;
  border-radius: 10px;
  bottom: 7px;
  left: 8px;
  min-width: unset !important;
  width: 96.3vw;
  border: 1px solid #787878;
}

.active-orders {
  position: fixed;
  bottom: 60px;
  right: 10px;
  z-index: 1000;
  &.opacity {
    animation: changeOpacity 10s infinite;
  }
  img {
    transition: opacity 10s ease-in-out;
    -webkit-animation: active-orders 10s ease-in-out infinite;
    animation: active-orders 10s ease-in-out infinite;
  }
  & > div {
    background: white;
    border-radius: 30px;
  }
}

.active-ride {
  position: fixed;
  bottom: 45px;
  left: 10px;
  z-index: 1000;
  &.opacity {
    animation: changeOpacity 5s infinite;
  }
  span {
    transition: opacity 5s ease-in-out;
    -webkit-animation: active-orders 5s ease-in-out infinite;
    animation: active-orders 5s ease-in-out infinite;
    font-size: 45px;
  }
}

[data-theme-version="dark"] .active-orders {
  & > div {
    background: #262424;
  }
}

[data-theme-version="dark"] .rce-mbox {
  background: #393636;
  .rce-mbox-left-notch, .rce-mbox-right-notch {
    fill: #393636;
  }
  .rce-mbox-time {
    color: #ffffff73;
  }
}

.active-orders-tabs {
  padding-top: 6px;
  padding-right: 6px;
  overflow-x: scroll;
  z-index: 2;
  .active-order {
    position: relative;
    padding: 5px;
    width: -webkit-fill-available;
    width: -moz-available;
    min-width: max-content;
    .order {
      box-shadow: 0 3px 7px rgba(50, 33, 17, 0.18);
    }
  }
  .selected {
    .order {
      box-shadow: rgb(255, 161, 73) 0 0 0 5px !important;
    }
  }
  .selected-menu {
    box-shadow: rgb(255, 161, 73) 0 0 0 2px !important;
  }
}

[data-theme-version="dark"] .active-orders-tabs {
  .active-order {
    .order {
      box-shadow: 0 3px 7px rgb(255 124 0 / 38%);
    }
  }
}

@keyframes changeOpacity {
  0%, 25% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  75%, 100% {
    opacity: 0.3;
  }
}

@-webkit-keyframes active-orders {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes active-orders {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.message-list {
  height: calc(100vh - 360px);
}