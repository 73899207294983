.delete-photo {
  padding: 1px 4px 1px 4px !important;
  position: absolute;
  top: 1px;
  right: 1px;
  cursor: pointer;
}
.delete-variant {
  position: absolute;
  top: 10px;
  right: 1px;
  cursor: pointer;
  z-index: 2;
}
.main-photo {
  padding: 1px 4px 1px 4px !important;
  position: absolute;
  top: 1px;
  left: 1px;
  cursor: pointer;
}
.main-picture {
  border: 2px solid blue;
}
.move-dish-modal {
  .css-26l3qy-menu {
    z-index: 2;
  }
}

/// table animation
@keyframes table {
  50% {
    background: #ffb800;
    color: #000;
  }
}
.waiter-request {
  animation: table 5s infinite;
}

// overdue-order
@keyframes overdue-order {
  50% {
    color: #000;
  }
}

.overdue-order {
  animation: overdue-order 5s infinite;
}

// dashboard
.dashboard {
  .white-border {
    border: 2px solid white !important;
    color: greenyellow !important;
  }
  .rc-slider-handle {
    opacity: 1;
  }
  .rc-slider-track {
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-radius: 0;
    height: 5px;
  }
}

[data-theme-version="dark"] .dashboard .rc-slider-track {
  border-left: 2px solid white;
  border-right: 2px solid white;
}

[data-theme-version="light"] .dashboard {
  .white-border {
    border-color: black !important;
  }
}

.unpaid-paychecks {
  position: absolute;
  right: -8px;
  top: -12px;
  padding: 2px 8px;
  background-color: #f35757;
  line-height: 1.5;
  border-radius: 0.75rem;
  font-weight: bold;
  z-index: 1;
}
// settings qr code

.preview {
  text-align: center !important;
  top: unset !important;
  left: unset !important;
  margin: auto !important;
}
.qr-code-wrapper {
  position: relative;
  text-align: center;
  top: -9999px;
  left: 9999px;
  border: 1px solid black;
  width: 300px;
  color: black;
  .qr-header {
    color: #F26600;
    font-weight: 700;
    font-size: 25px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.02em;
  }
  .logo {
    z-index: 2;
    height: 50px;
    border-radius: 10px;
  }
  .table-name {
    margin-top: 10px;
    font-size: 35px;
  }
  .qr {
    text-align: center;
    .qr-code {
      margin: auto;
    }
  }
  .payment-methods {
    button {
      padding: 0 10px;
      background: black;
      border-color: black;
    }
  }
  ul {
    font-size: 16px;
  }
}
.support-input {
  position: fixed;
  bottom: 50px;
  left: 0;
}

.rce-mlist {
  //margin-bottom: 50px;
}

[data-theme-version=dark] .measure-select > div {
  background: #161615;
  border-color: #333a54;
  & > div {
    & > div {
      color: white;
    }
  }
}

// PayChecks

.pay-checks-date-picker {
  min-height: 38px;
  text-align: center;
}