.special-label {
  display: none;
}
.delivery-point {
  overflow: hidden;
}
.cutlery-counts {
  .cutlery-count {
    font-size: 10px;
  }
}
.accordion__item {
  .order-type {
    padding: 10px 15px;
    border-radius: 10px;
  }
}
.order-dish {
  position: relative;
  background: rgba(241, 241, 241, 0.8);
  padding: 5px 10px;
  color: #2F2D2A;
  margin-bottom: 10px;
  border-radius: 10px;
  min-height: 48px;
  font-size: 16px;
  vertical-align: center;
  box-shadow: 0 3px 7px rgb(50 33 17 / 18%);
  //border: 1px solid #d7dae3;
  .order-count {
    display: block;
    cursor: pointer;
    background: #DDDDDD;
    font-size: 16px;
    padding: 0 6px;
    box-shadow: none;
    border-radius: 5px;
    line-height: 21px;
  }
}
[data-theme-version="dark"] .order-dish {
  //background: linear-gradient(136deg, rgba(19, 8, 0, 0.25) 75%, rgb(128 70 0 / 21%) 100%);
  background: #393636;
  //box-shadow: rgb(63 30 0) 0 0 5px 0;
  .order-count {
    background: #2b2725;
    color: #FB855A;
  }
}
.charities {
  background: rgb(241, 241, 241);
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 3px 7px rgb(50 33 17 / 18%);
}
[data-theme-version="dark"] .charities {
  background: #393636;
  color: white;
}