.form-group {
  .dropzone {
    border: 1px dotted black;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px 0;
    p {
      margin: 0;
    }
  }
  .user-photo {
    border: none;
    font-weight: bold;
    border-radius: 10px;
    padding: 10px;
  }
}
#delivery-map-sm {
  z-index: 1;
  height: 150px;
}
#delivery-map {
  z-index: 1;
  height: 150px;
  .leaflet-control-zoom {
    display: none;
  }
}
.theme-select {
  border-radius: 10px;
  button {
    border-radius: 3px;
  }
  .inner {
    background: #FFFFFF;
    border-radius: 5px;
  }
}
.white-theme-select {
  background: #F2F2F2;
  .inner {
    h6 {
      color: #2F2D2A !important;
    }
  }
}
.black-theme-select {
  background: #161615;
  .inner {
    background: #262626;
    h6 {
      color: #F5F5F5;
    }
  }
}
@media (max-width: 767px) {
  .card {
    .card-footer {
      padding-right: 0;
      padding-left: 0;
      button {
        width: 100%;
      }
    }
  }
}
