#full-map {
  height: calc(100vh - 214px);
  min-height:100%;
  z-index: 1;
}
[data-theme-version="dark"] .leaflet-container {
  .leaflet-map-pane {
    .leaflet-tile-pane {
      filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);
    }
  }
}
.leaflet-control-zoom {
  display: none;
}
.leaflet-div-icon {
  width: 20px !important;
  background: transparent;
  border: none;
}
#delivery-map {
  height: calc(100vh - 214px);
  min-height:100%;
  z-index: 1;
}
.leaflet-bottom {
  .leaflet-control-attribution {
    display: none;
  }
}
#full-map-settings {
  height: calc(100vh - 260px);
  min-height: 100%;
  z-index: 1;

  .calcite-theme-light {
    display: none;
  }
}
.admin-settings {
  .form-group > div {
    z-index: 2;
  }

  #full-map-settings-2 {
    height: calc(100vh - 95px);
    min-height: 100%;
    z-index: 1;
  }
}
.my-masonry-grid > div {
  height: 100%;
}
.restaurant-description {
  text-align: justify;
}
.category-home {
  color: #262626;
  z-index: 1;
  line-height: 1;
  bottom: 0;
  left: 0;
  font-size: 12px;
  margin-top: -5;
}
[data-theme-version="dark"] .category-home {
  color: rgb(245,245,245);
}
.restaurants {
  height: calc(100vh - 214px);
  overflow-y:scroll;
  z-index: 1;
  .no-results {
    background: rgba(38,38,38, 0.2);
    border-radius: 10px;
    font-size: 24px;
    text-align: center;
    margin: 0 10px;
    overflow-y: scroll;
  }
  .restaurant {
    background: #262626;
    border-radius: 20px;
    .style-module_starRatingWrap__q-lJC {
      touch-action: auto;
    }
    .distance {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      padding-left: 2px;
      padding-right: 2px;
    }
  }
  .btn-menu {
    padding: 10px 5px 10px 15px;
  }
  .card-body {
    background: linear-gradient(180deg, rgba(19, 11, 1, 0.3) 0%, rgba(19, 11, 1, 0.9) 99.41%);
  }
  .img-bx {
    height: 0px;
  }
}
.css-tlfecz-indicatorContainer > svg {
  font-size: 20px;
}
.city-finder {
  position: relative;
  z-index: 2;
  display: flex;
  cursor: pointer;
}
#start-point {
  margin-right: 10px;
}
.city-select {
  width: 100%;
  border-radius: 10px;
  & > div {
    border-radius: 10px;
    height: 41px;
  }
  .css-26l3qy-menu {
    div {
      background: #f5f5f5;
      border-radius: 10px;
      color: black;
    }
  }
}
[data-theme-version="dark"] .city-select {
  & > div {
    background: #161615;
    border-color: #333a54;
    color: white;
    & > div {
      color: white;
      & > div {
        color: white;
      }
    }
  }
  .css-1uccc91-singleValue, .css-b8ldur-Input {
    color: white !important;
  }
  .css-26l3qy-menu {
    div {
      background: #161615;
      color: #f5f5f5;
    }
  }
}
.all-page {
  .zero-padding {
    padding: 0;
    position: relative;
    .search-here {
      position: absolute;
      z-index: 2;
      top: 20px;
      left: 50%;
      transform: translate(-50%, -50%);
      .btn {
        width: max-content;
      }
    }
  }
  .sorting {
    button {
      white-space: nowrap;
      margin-right: 10px;
      box-shadow: 0px 3px 8px rgba(21, 13, 2, 0.2);
    }
    .active {
      background: linear-gradient(153.09deg, #FFA149 -29.54%, #FB4A35 111.32%) !important;
      color: white !important;
      box-shadow: 0px 3px 8px rgba(21, 13, 2, 0.2) !important;
    }
  }
}
.leaflet-popup-content-wrapper {
  .leaflet-popup-content {
    a {
      color: black;
    }
  }
}
@media (max-width: 767px) {
  body {
    .home-menu {
      .modal-lg {
        margin: auto;
        .modal-body {
          padding: 10px;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .content-body {
    .container-fluid {

      .city-finder {
        width: 100%;
      }
      .all-page {
        .zero-padding {
          position: fixed;
          left: 0;
          top: 80px;
          .search-here {
            top: 150px;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .restaurants {
          position: fixed;
          bottom: 40px;
          left: 0;
          height: 182px;
          padding: 0;
          overflow-x: scroll;
          overflow-y: hidden;

          .my-masonry-grid {
            flex-direction: row;
            place-content: stretch flex-start !important;

            & > div {
              flex-direction: row !important;
              width: unset !important;
            }

            .card {
              min-width: 270px;
              .card-body {
                max-height: 164px;
              }
            }
          }
          .restaurant-text {
            height: 81px;
            overflow-y: scroll;
          }
        }
        .sorting {
          position: fixed;
          bottom: 215px;
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          align-items: center;
          justify-content: left;
          overflow-x: scroll;
          overflow-y: hidden;
          padding-top: 3px;
          padding-bottom: 8px;
          z-index: 1;
          cursor: pointer;
        }
      }

      #full-map {
        height: calc(100vh - 80px);
      }

      .card-header .start-review svg {
        height: 15px;
        width: 15px;
      }
    }
  }
}
.clip-loader {
  margin: 0 auto;
}
.install-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
  .guidance-wrapper {
    width: 100%;
    .guidance-image {
      width: 100%;
      img {
        width: 50%;
      }
    }
    .guidance-cancel {
      display: none;
    }
  }
  .ath-prompt-logo {
    border: none;
    width: 100px;
  }
}

@media (max-width: 767px) {
  .install-container {
    .guidance-wrapper {
      .guidance-image {
        img {
          width: 100%;
        }
      }
    }
  }
}

.btn-outline-dark {
  background-color: transparent !important;
  &:hover {
    color: inherit !important;
  }
}

[data-theme-version="dark"] .btn-outline-dark {
  color: #ffffff !important;
  border-color: #ffffff !important;
}

.sidebar-close {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 18rem;
  top: 0;
  z-index: 4;
}
@media (max-width: 991px) {
  .sidebar-close {
    left: 14rem;
  }
}
body {
  .red-notice {
    color: #F45045 !important;
  }
}

.nav-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  min-width: 40px;
  .selected-dishes {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.categories-filter {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
  overflow-x: scroll;
  z-index: 1;
  cursor: pointer;
  height: 65px;
  background: rgba(245,245,245, 0.7);
  border-radius: 10px;
  overflow-y: hidden;
  margin-top: 10px;
  .category-filter {
    position: relative;
    text-align: center;
    padding: 0 5px;
    border-radius: 10px;
    min-width: 65px;
    .category-filter-emoji {
      font-size: 28px;
    }
    .category-filter-number {
      position: absolute;
      right: 5px;
      top: 1px;
      color: #F45045;
      font-weight: 700;
    }
  }
  .selected-category {
    background: #FFD2C1;
    height: 63px;
    margin-top: 1px;
    margin-left: 1px;
    margin-right: 1px;
  }
}
.sorting-categories {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: left;
  overflow-x: scroll;
  z-index: 1;
  cursor: pointer;
  top: 40px;
  left: -6px;
  padding: 9px 6px;
  button {
    white-space: nowrap;
    margin-right: 10px;
    box-shadow: 0px 3px 8px rgba(21, 13, 2, 0.2);
  }
  .active {
    background: linear-gradient(153.09deg, #FFA149 -29.54%, #FB4A35 111.32%) !important;
    color: white !important;
    box-shadow: 0px 3px 8px rgba(21, 13, 2, 0.2) !important;
  }
}
[data-theme-version="dark"] .categories-filter {
  background: rgba(38,38,38, 0.7);
  color: rgba(245,245,245, 1);
  .selected-category {
    //background: #393636;
    .category-home {
      color: #262626;
    }
  }
}