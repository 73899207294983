@import './components/login';
@import './components/home';
@import './components/checkout';
@import './components/menu';
@import './components/orders';
@import './components/dropdown';
@import './components/profile';
@import './components/bottomNav';
@import './components/restaurant/show';
@import './components/admin/settings';
@import './components/company';

.MuiInput-input {
  border-radius: 10px;
  text-align: center;
}
[data-theme-version="dark"] .MuiFormControl-root {
  .MuiInput-input, label {
    color: white;
  }
}

.Toastify__toast-container {
  .Toastify__toast {
    margin: 20px;
    border-radius: 10px;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    .Toastify__close-button {
      display: none;
    }
  }
  .Toastify__toast--default {
    background: linear-gradient(153.09deg, #FFA149 -29.54%, #FB4A35 100%);
    span {
      color: #ffffff;
    }
  }
  .Toastify__toast--info {
    background: linear-gradient(180deg, #257CFE -25%, #0B4FB5 136%);
    span {
      color: #ffffff;
    }
  }
  .Toastify__toast--error {
    background: linear-gradient(183.78deg, #ED2B2B -35.54%, #760A5E 135.18%);
    span {
      color: #ffffff;
    }
  }
  .Toastify__toast--success {
    background: linear-gradient(153.09deg, #C0F168 -29.54%, #79BB25 60%);
    span {
      color: #ffffff;
    }
  }
  .Toastify__toast--warning {
    background: linear-gradient(153.09deg, #ffe12e -29.54%, #fcb93a 100%);
    span {
      color: #212529;
    }
  }
}

//slider

.horizontal-slider {
  margin-bottom: 25px;
  margin-top: 15px;
  .slider-thumb {
    border-radius: 10px;
    padding: 5px;
    top: -13px;
    background: linear-gradient(180deg, #257CFE -25%, #0B4FB5 136%);
    color: white;
    cursor: pointer;
  }
  .slider-track {
    background: linear-gradient(153.09deg, #ffe12e -29.54%, #fcb93a 100%);
    height: 6px;
    border-radius: 10px;
  }
  .slider-red-green {
    background: linear-gradient(153.09deg, #ED2B2B, #79BB25 100%);
    height: 6px;
    border-radius: 10px;
  }
  .example-mark {
    padding: 1px;
    background: white;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 1px;
  }
}
body {
  .red-tooltip {
    color: red
  }
  .green-tooltip {
    color: green
  }
  .menu-icon {
    display: inline-block;
    font-size: 28px;
    width: 50px;
    text-align: center;
  }
  .delay-tooltip {
    z-index: 2;
  }
  [data-tippy-root] {
    z-index: 1 !important;
  }
  .apple-auth-btn {
    border-radius: 30px;
    padding: 0 30px;
  }
  @media (max-width: 450px) {
    .bd-example-modal-lg {
      display: flex !important;
      align-items: flex-end !important;;
      justify-content: center !important;
      padding: 0 !important;
      margin: 0 !important;
      .modal-dialog {
        width: 100%;
        margin: 0;
      }
    }
  }
  .play-store {
    & > img {
      width: 150px;
    }
  }
  .red-toggle .react-toggle-track{
    background: red;
  }
  .red-toggle .react-toggle-thumb{
    border-color: red;
  }
  .red-toggle.react-toggle--checked .react-toggle-track{
    background: green;
  }
  .red-toggle.react-toggle--checked .react-toggle-track:hover {
    background: green;
  }
  .red-toggle.react-toggle--checked .react-toggle-thumb{
    border-color: green;
  }
  .primary-background {
    background: linear-gradient(153.09deg, #FFA149 -29.54%, #FB4A35 100%) !important;
  }
}