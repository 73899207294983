[data-theme-version="dark"] .menu-content {
  .dish {
    background: #262626;
  }
}
.dish-rate {
  float: right;
  button {
    padding-left: 10px;
    padding-right: 10px;
    .fa-thumbs-o-up {
      color: #8D8B89;
    }
    .fa-thumbs-o-down {
      color: #8D8B89;
    }
    i {
      font-size: 14px;
      margin-right: 3px;
    }
    span {
      font-family: Nunito;
      color: #8D8B89;
      font-size: 12px;
    }
  }
}
.order-types {
  background: #F5F5F5;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  .order-type {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33% !important;
    height: 100%;
    text-align: center;
    border-radius: 8px;
  }
}
[data-theme-version="dark"] .order-types {
  background: #393636;
}
.selected-dishes {
  position: absolute;
  right: -6px;
  top: -6px;
  color: white;
  background: linear-gradient(153.09deg, #FB4A35 -29.54%, #FFA149 100%);
  border-radius: 12px;
  height: 24px;
  width: 25px;
  text-align: center;
  z-index: 1;
}
.menu-content {
  margin-bottom: 80px;
  .order-request {
    td:nth-child(1) {
      width: auto;
    }
    td:nth-child(2) {
      width: 150px;
    }
    td:nth-child(3) {
      width: 47px;
      padding-right: 0;
      padding-left: 7px;
    }
    td:nth-child(4) {
      & > div {
        justify-content: right;
      }
    }
    .restaurant, .card-body {
      background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
      .img-bx {
        max-height: 30px;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
      }
      .star-review {
        color: white;
        word-break: break-word;
      }
    }
  }
  & > a, .make-table-order {
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    border-radius: unset !important;
  }
  .dish-wrapper {
    position: relative;
    overflow: hidden;
    background: white;
    box-shadow: 0px 2px 12px rgba(29, 10, 3, 0.2);
    //background: linear-gradient(90deg, rba(71, 71, 71, 1) -10.97%, rgba(140, 140, 140, 0.42) 105.81%);
    //background: #C6BCB1;
    .dish-image {
      width: 100%;
      position: absolute;
      height: 140px;
      left: 0;
      top: 0;
      z-index: 1;
      object-position: -99999px 99999px;
    }
    .dish-image-right {
      width: 50%;
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;
      z-index: 1;
      object-position: -99999px 99999px;
    }
  }
  .dish {
    margin-bottom: 1.875rem;
    transition: all .5s ease-in-out;
    border: 0 solid transparent;
    border-radius: 10px;
    height: calc(100% - 30px);
    min-width: 0;
    //background: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    word-wrap: break-word;
    //background: #C6BCB1;
    overflow: hidden;
    .dish-name {
      //background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    }
    .dish-name-inner {
      padding: 5px 5px 5px 15px;
    }
    .dish-price {
      font-size: 20px;
      padding: 5px 10px;
      background: #22211F;
      border-radius: 10px;
      color: #F99724;
    }
    .dish-count {
      font-size: 18px;
      padding: 4px 15px;
      background: #f4f4f4;
    }
  }
  .dish-min-height {
    min-height: 120px;
    overflow: hidden;
  }
  .dish-variations {
    overflow: hidden;
    width: 100%;
  }
  .menu-category {
    background: #FFFFFF;
    width: 100%;
    box-shadow: 0 3px 10px rgba(29, 10, 3, 0.08);
    height: 40px;
    border: none;
    border-radius: 10px;
    padding-left: 13px;
    padding-right: 13px;
    .emoji {
      font-size: 23px;
      padding: 4px 5px 1px 5px;
      height: 28px;
      border-radius: 5px;
      display: flex;
      align-items: center;
    }
  }
  .company-category {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 2;
    padding-top: 100%;
    position: relative;
    height: 80px;
  }
  .is-sticky {
    position: fixed;
    width: calc(100vw - 16px);
    top: 84px;
    z-index: 2;
    h6 {
      color: white;
    }
  }
  .is-sticky-category {
    position: fixed;
    top: 80px;
    z-index: 3;
    height: 95px;
    width: -webkit-fill-available;
    width: -moz-available;
    h6 {
      color: white;
    }
  }
  .is-sticky-nav-tab {
    position: fixed;
    top: 124px;
    width: 100%;
    z-index: 2;
    background: transparent;
    border-bottom: 0;
    padding-top: 10px;
    .subcategory {
      box-shadow: 0px 3px 8px rgba(21, 13, 2, 0.2);
    }
  }
  .is-sticky-restaurants {
    position: fixed;
    top: 170px;
    width: 100%;
    z-index: 3;
    border-bottom: 0;
  }
  .is-sticky-company-nav-tab {
    position: fixed;
    top: 267px;
    width: 100%;
    z-index: 2;
    background: transparent;
    border-bottom: 0;
    padding-top: 10px;
    .subcategory {
      box-shadow: 0px 3px 8px rgba(21, 13, 2, 0.2);
    }
  }
  .subcategories {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    overflow-x: scroll;
    padding: 10px 0;
    z-index: 3;
    cursor: pointer;
    .subcategory {
      background: #FFFFFF;
      border-radius: 8px;
      box-shadow: 0px 3px 8px rgba(21, 13, 2, 0.2);
      a {
        color: #130B01;
      }
      .nav-link {
        width: max-content;
      }
      .active {
        background: linear-gradient(153.09deg, #FFA149 -29.54%, #FB4A35 111.32%);
        color: white;
        border-radius: 8px;
      }
    }
  }
  .show-more {
    position: absolute;
    bottom: 0;
    width: 46%;
    text-align: right;
    cursor: pointer;
  }
}

.extra-menu {
  background: white;
  padding: 10px 12px !important;
}

[data-theme-version="dark"] .menu-category {
  background: #262626;
}

@media only screen and (max-width: 575px) {
  .menu-content {
    .dish {
      margin-bottom: 0.938rem;
      height: calc(100% - 0.938rem);
    }
  }
}

@media (max-width: 1199px) {
  .menu-content {
    .is-sticky {
      top: 84px;
    }

    .is-sticky-nav-tab {
      top: 124px;
    }
  }
}

@media (min-width: 768px) {
  .menu-content {
    .is-sticky {
      width: calc(100vw - 5%);
    }
  }
}

@media (max-width: 991px) {
  .menu-content {
    padding: 0;
    .accordion__body--text {
      padding-left: 0;
      padding-right: 0;
      .order-request {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1px;

        tbody {
          tr {
            padding: 0;

            td {
              padding-left: 0;

              .input-group {
                display: contents;
                width: -webkit-fill-available;

                input {
                  width: -webkit-fill-available;
                }

                .input-group-append {

                }
              }

              .btn-rounded {
                font-size: 0;

                span {
                  font-size: initial;
                }
              }
            }

            td:nth-child(2) {
              width: 137px;
            }

            .media {
              width: inherit;
            }

            .restaurant {
              .img-bx {
                max-height: 30px;
              }
            }
          }
        }
      }
    }
  }
}

//// Tables
.tables {
  .cursor-y {
    cursor: ns-resize;
  }

  .cursor-x {
    cursor: ew-resize;
  }

  .box {
    border: 1px solid #999;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    padding: 10px;
  }

  .resize-table > div {
    &:before{
      content: '';
      background:url('https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSj3XfIpf-OOJRCcWz4iN2CU3qMKVVbj0p0bRvKNGVo1U9pk_8ZIlyR8pWreA');
      background-size:cover;
      position:absolute;
      width:10px;
      height:10px;
    }
  }
}