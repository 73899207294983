#root, body, .modal {
  .react-tel-input {
    .form-control {
      width: 100%;
      border-radius: 10px;
      height: 41px;
      border-color: #d7dae3;
    }
    & > .flag-dropdown {
      border-radius: 10px;
    }
  }
}
[data-theme-version="dark"] #root, [data-theme-version="dark"] .modal {
  .react-tel-input {
    .form-control {
      border-color: #333a54;
    }
    & > .flag-dropdown {
      border-color: #333a54;
      background-color: #262626;
    }
  }
}